export const pages = [
  { name: "Home", id: "hero", hideNavbar: true },
  { name: "Who Am I", id: "whoarewe" },
  { name: "How Can I Help", id: "howcanwehelp" },
  { name: "Need Something Unique", id: "needsomethingunique", hideNavbar: true },
  { name: "About Me", id: "hereatcodehardy" },
  { name: "Pricing", id: "pricingplans" },
  { name: "Portfolio", id: "portfolio" },
  { name: "Contact Me", id: "contact" },
];
