import { Card, Center, Heading, HStack, Icon, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";
import { FiStar } from "react-icons/fi";

interface PricingCardProps {
  stars: number;
  title: string;
  text: string;
  price: string;
}

export const PricingCard: React.FC<PricingCardProps> = ({ stars, text, title, price }) => {
  return (
    <Card as={SimpleGrid} templateRows={"1fr 1fr 2fr"} gap={5} bg="secondary" p={10}>
      <Center flexDirection={"row"}>
        {Array.from({ length: stars }).map((_, index) => (
          <Icon key={index} as={FiStar} color="white" fontSize="4xl" mr={2} />
        ))}
      </Center>

      <Center flexDir="column">
        <Heading pb={2} as="h1" fontWeight={700} size={{ base: "md", md: "lg" }} color="white">
          {title}
        </Heading>
        <Text textAlign="center" pb={4} fontWeight={600} fontSize={{ base: "lg", md: "xl" }} color="white">
          £{price}/pm
        </Text>
      </Center>

      <Center>
        <Text textAlign="center" pb={4} fontWeight={600} fontSize={{ base: "lg", md: "xl" }} color="white">
          {text}
        </Text>
      </Center>
    </Card>
  );
};
