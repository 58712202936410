import { Box, Center, Container, Heading, SimpleGrid, Text } from "@chakra-ui/react";
import { FiCloud, FiGlobe, FiTool, FiDatabase, FiMonitor } from "react-icons/fi";
import { FeaturesCard } from "../components/FeaturesCard";

export const HowCanWeHelp = () => {
  return (
    <Box id="howcanwehelp" bg="primary" w="100vw" py="10%">
      <Container p={{ base: 16, lg: 24 }} maxW="full">
        <Center h="200px" flexDir="column" alignItems="flex-start">
          <Box>
            <Text pb={2} fontWeight={600} fontSize={{ base: "lg", md: "xl" }} color="secondary">
              How Can I Help?
            </Text>
            <Heading pb={2} as="h1" fontWeight={600} size={{ base: "md", md: "lg" }} color="white">
              Affordable, Tailored IT Solutions
            </Heading>
            <Heading pb={4} as="h2" fontWeight={600} size={{ base: "md", md: "lg" }} color="white">
              Creative Brilliance
            </Heading>
          </Box>
        </Center>
        <SimpleGrid templateColumns={{ base: "1fr", md: "1fr 1fr 1fr" }} templateRows={"1fr 1fr"} columnGap={12} rowGap={12}>
          <FeaturesCard direction="left" title="Web & App Development" icon={FiCloud} text="I build stunning, custom websites and mobile apps designed for performance, scalability, and user engagement. Whether it’s a sleek website or a feature-rich app, I ensure your vision comes to life." />
          <FeaturesCard direction="top" title="Cloud Solutions & Hosting" icon={FiGlobe} text="Using platforms like GCP and AWS, I offer scalable, secure hosting and cloud solutions. Perfect for startups and growing businesses needing reliability without overspending." />
          <FeaturesCard direction="right" title="SEO & Analytics" icon={FiTool} text="I make sure your business gets found online and stays competitive. With data-driven insights, I help boost your traffic and conversions." />
          <FeaturesCard direction="left" title="IT Consultancy" icon={FiTool} text="Need advice on your tech strategy? I can guide you on everything from system architecture to optimising workflows, aligning your tech with your goals." />
          <FeaturesCard direction="bottom" title="Database Management & Setups" icon={FiDatabase} text="Your data is critical - I design, optimise, and manage your databases to keep everything running smoothly." />
          <FeaturesCard direction="right" title="Interactive Features & Games" icon={FiMonitor} text="Want to stand out? I can build custom interactive features like spin-to-win promotions or mini-games to delight your customers and elevate your brand." />
        </SimpleGrid>
      </Container>
    </Box>
  );
};
