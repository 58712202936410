import { Box, Center, Container, Heading, HStack, Image, SimpleGrid, Text } from "@chakra-ui/react";
import { motion, useAnimation } from "framer-motion";
import { InView } from "react-intersection-observer";

const OurTeamImg = require("../assets/code.jpeg");

export const AboutMe = () => {
  const MotionBox = motion(Box);
  const controlsY = useAnimation();

  const handleInViewChange = (inView: boolean) => {
    controlsY.start({ y: inView ? 0 : 200, opacity: inView ? 1 : 0 });
  };

  return (
    <Box id="ourteam" bg="primary" w="100vw" py="10%" pos="relative" overflow="hidden" height={{ base: "fit-content", lg: "auto" }}>
      <Container p={{ base: 16, lg: 24 }} maxW="full">
        <InView as="div" triggerOnce={false} threshold={0.4} onChange={handleInViewChange}>
          <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={{ base: "10%", lg: "15%", xl: "20%" }}>
            {/* Left Side: Text */}
            <Center h="100%" flexDir="column" alignItems="flex-start">
              <MotionBox animate={controlsY} initial={{ y: 200, opacity: 0 }} transition={{ duration: 1 }}>
                <Text pb={2} fontWeight={600} fontSize={{ base: "lg", md: "xl" }} color="secondary">
                  Get to Know Me
                </Text>
                <Heading pb={2} as="h1" fontWeight={600} size={{ base: "md", md: "lg" }} color="white">
                  Passionate About Tech
                </Heading>
                <Heading pb={4} as="h2" fontWeight={600} size={{ base: "md", md: "lg" }} color="secondary">
                  Driven by Innovation
                </Heading>

                <Text lineHeight="2" fontSize={{ base: "lg", md: "xl" }} color="whitesmoke" w="100%">
                  With over 8 years of experience in front-end and back-end development, cloud infrastructure, DevOps, and IT consultancy, I’ve worked with startups and global brands alike to deliver exceptional solutions.
                </Text>
                <Text lineHeight="2" fontSize={{ base: "lg", md: "xl" }} color="whitesmoke" w="100%">
                  While I primarily handle projects end-to-end myself, I also leverage a network of subcontractors and specialised talent for design and other niche needs, ensuring every project is delivered with excellence.
                </Text>
              </MotionBox>
            </Center>

            {/* Right Side: Images */}
            <Center pos="relative" w="100%">
              <Image src={OurTeamImg} objectFit="contain" alt="About Me Image 1" borderRadius="lg" height="auto" width="40%" mr="10%" />
              <Image src={OurTeamImg} objectFit="contain" alt="About Me Image 2" borderRadius="lg" height="auto" width="40%" />
            </Center>
          </SimpleGrid>
        </InView>
      </Container>
    </Box>
  );
};
