import { Box, Container, Heading, HStack, Icon, Input, SimpleGrid, Textarea, Button, VStack, Spinner, Text, useToast } from "@chakra-ui/react";

import { InView } from "react-intersection-observer";
import { FiArrowRight } from "react-icons/fi";
import ReactGA from "react-ga4";
import { useState } from "react";

interface FormData {
  name: string;
  email: string;
  phone: string;
  message: string;
}

export const ContactForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<Partial<FormData>>({});
  const toast = useToast();

  const validateForm = (data: FormData) => {
    const errors: Partial<FormData> = {};

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!data.email || !emailRegex.test(data.email)) {
      errors.email = "Please enter a valid email address.";
    }

    // Phone number validation
    const phoneRegex = /^[0-9]{10,15}$/; // Accepts 10-15 digits
    if (!data.phone || !phoneRegex.test(data.phone)) {
      errors.phone = "Please enter a valid phone number.";
    }

    // Message validation
    if (!data.message || data.message.trim() === "") {
      errors.message = "Message cannot be empty.";
    }

    return errors;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    const formData = new FormData(event.currentTarget);
    const data: FormData = Object.fromEntries(formData.entries()) as unknown as FormData;

    const validationErrors = validateForm(data);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setIsLoading(false);
      return;
    }

    setErrors({});
    ReactGA.event({
      category: "Contact Form",
      action: "Submit Form",
      label: "Contact Form Submission",
    });

    try {
      const response = await fetch("https://mh246l7hjnszk76sbyx4bda3u40qcwul.lambda-url.eu-west-2.on.aws", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        toast({
          title: "Message Sent.",
          description: "Your message has been sent successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        throw new Error("Failed to send message.");
      }
    } catch (error) {
      toast({
        title: "Error.",
        description: "An error occurred while sending the message.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box id="contact" bg="primary" w="100vw" py="10%" pos="relative" overflow="hidden" height={{ base: "fit-content", lg: "auto" }}>
      <Container p={{ base: 16, lg: 24 }} maxW="full">
        <Box my={10}>
          <HStack spacing={10}>
            <Heading pb={2} as="h1" fontWeight={700} size={{ base: "2xl", md: "4xl" }} color="secondary">
              GET IN TOUCH
            </Heading>
            <Heading
              pb={2}
              as="h1"
              fontWeight={700}
              size={{ base: "2xl", md: "4xl" }}
              sx={{
                WebkitTextStroke: "1px",
                WebkitTextFillColor: "transparent",
                WebkitTextStrokeColor: "#F29420",
              }}
            >
              NOW
            </Heading>
            <Icon as={FiArrowRight} color="secondary" fontSize="4xl" />
          </HStack>
        </Box>
        <div>
          <form onSubmit={handleSubmit}>
            <SimpleGrid mt={5} columns={{ base: 1, md: 2 }} spacing={8} alignItems="stretch">
              {/* Left Side */}
              <Box>
                <VStack spacing={4} align="stretch">
                  <Input name="name" borderColor="secondary" bg="transparent" color="white" _placeholder={{ color: "white" }} placeholder="Your Name" />
                  <Textarea minH={24} name="message" borderColor="secondary" bg="transparent" color="white" _placeholder={{ color: "white" }} placeholder="Your Message" resize="none" />
                  {errors.message && <Text color="red.500">{errors.message}</Text>}
                </VStack>
              </Box>
              {/* Right Side */}
              <Box>
                <VStack spacing={4} align="stretch" height="100%">
                  <Input name="phone" placeholder="Phone Number" borderColor="secondary" color="white" _placeholder={{ color: "white" }} bg="transparent" />
                  {errors.phone && <Text color="red.500">{errors.phone}</Text>}
                  <Input name="email" placeholder="Email Address" borderColor="secondary" color="white" _placeholder={{ color: "white" }} bg="transparent" />
                  {errors.email && <Text color="red.500">{errors.email}</Text>}
                  <Button type="submit" variant="secondary" isLoading={isLoading} spinner={<Spinner size="sm" />}>
                    {isLoading ? "Sending..." : "Get Started ->"}
                  </Button>
                </VStack>
              </Box>
            </SimpleGrid>
          </form>
        </div>
      </Container>
    </Box>
  );
};
