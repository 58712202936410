import { Box, Center, Container, Heading, SimpleGrid, Text, Button, HStack, Divider } from "@chakra-ui/react";
import { motion, useAnimation } from "framer-motion";
import { InView } from "react-intersection-observer";
import { PricingCard } from "../components/PricingCard";

export const PricingPlans = () => {
  const MotionBox = motion(Box);
  const controlsY = useAnimation();

  const handleInViewChange = (inView: boolean) => {
    controlsY.start({ y: 0, opacity: 1 });
  };

  return (
    <Box id="pricingplans" bg="primary" w="100vw" py="10%" pos="relative" overflow="hidden" height={{ base: "fit-content", lg: "auto" }}>
      <Container p={{ base: 16, lg: 24 }} maxW="full">
        <InView as="div" triggerOnce={false} threshold={0.4} onChange={handleInViewChange}>
          {/* Introductory Text */}
          <Center h="100%" flexDir="column" alignItems="flex-start" mb={10}>
            <MotionBox animate={controlsY} initial={{ y: 200, opacity: 0 }} transition={{ duration: 1 }}>
              <Text pb={2} fontWeight={600} fontSize={{ base: "lg", md: "xl" }} color="secondary">
                Transparent, Fair Pricing
              </Text>
              <Heading pb={2} as="h1" fontWeight={600} size={{ base: "md", md: "lg" }} color="white">
                Plans That Fit Your Business
              </Heading>
              <Heading pb={4} as="h2" fontWeight={600} size={{ base: "md", md: "lg" }} color="secondary">
                Cost-Effective and Scalable
              </Heading>
              <Text color="whitesmoke" fontSize="lg" mb={4}>
                Whether you're just starting or scaling to new heights, I work closely with you to design cost-effective solutions that align with your vision. Every business is unique, and so are the challenges it faces. Let's figure out the best way to overcome yours - together.
              </Text>
              <Text color="whitesmoke" fontSize="lg">
                Not sure where to start? Choose a time that suits you to discuss your needs. Whether it’s a quick discovery call or a detailed consultation, I’m here to help you achieve your goals.
              </Text>
            </MotionBox>
          </Center>

          {/* Calendly Buttons */}
          <HStack spacing={4} justifyContent="center" mb={10}>
            <Button variant="secondary" size="lg" onClick={() => window.open("https://calendly.com/marcos-code-hardy/15-minute-discovery-call", "_blank")}>
              15-Minute Discovery Call
            </Button>
            <Button variant="secondary" size="lg" onClick={() => window.open("https://calendly.com/marcos-code-hardy/30min", "_blank")}>
              30-Minute Consultation
            </Button>
          </HStack>

          {/* Divider to Separate Sections */}
          <Divider borderColor="secondary" my={8} />

          {/* Support Plans Section */}
          <Heading textAlign="center" pb={6} as="h1" fontWeight={600} size={{ base: "lg", md: "xl" }} color="white">
            Explore Our Support Plans
          </Heading>
          <Text textAlign="center" color="whitesmoke" mb={6}>
            Choose from our flexible support options, designed to match your business needs and budget. From quick fixes to ongoing monitoring, there’s a plan for everyone.
          </Text>
          <SimpleGrid mt={10} columns={{ base: 1, lg: 3 }} spacing={"5%"}>
            <PricingCard title="Basic" price="25" text="A budget-friendly option for businesses needing minimal support. Includes up to 2 hours per month for quick updates or small fixes." stars={1} />
            <PricingCard title="Starter" price="50" text="Ideal for startups and small businesses that need occasional updates or support. Includes up to 5 hours per month for updates, bug fixes, or small-scale maintenance." stars={2} />
            <PricingCard title="Growth" price="150" text="Perfect for growing companies with evolving needs. Includes 10 hours of support per month, along with proactive monitoring and optimisation." stars={3} />
          </SimpleGrid>
        </InView>
      </Container>
    </Box>
  );
};
