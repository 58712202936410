import { Hero } from "../components";

import { ContactForm } from "./ContactForm";

import { HereAtCodeHardy } from "./HereAtCodeHardy";
import { HowCanWeHelp } from "./HowCanWeHelp";
import { NeedSomethingUnique } from "./NeedSomethingUnique";
import { MyExpertise } from "./MyExpertise";
import { AboutMe } from "./AboutMe";
import { Portfolio } from "./Portfolio";
import { PricingPlans } from "./PricingPlans";
import { WhoAreWe } from "./WhoAreWe";
import Snowfall from "react-snowfall";

export const LandingPage = () => (
  <>
    <Snowfall style={{ zIndex: 9999999999999999 }} />
    <Hero />
    <WhoAreWe />
    <HowCanWeHelp />
    <NeedSomethingUnique />
    <AboutMe />
    <PricingPlans />
    <Portfolio />
    <ContactForm />
  </>
);
