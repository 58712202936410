import { Box, Button, Container, Heading, Img, Stack, Text } from "@chakra-ui/react";
import { InView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";
import ReactGA from "react-ga4";
const HeroImg = require("../../assets/hero-image.png");

export const Hero = () => {
  const controls = useAnimation();

  return (
    <Box id="hero" position="relative" height={{ base: "1000px", lg: "80vh" }}>
      <Container p={{ base: "16", md: "24" }} height="full" minW="100vw" bgColor="primary">
        <Stack direction={{ base: "column", lg: "row" }} spacing={{ base: "16" }} align={{ lg: "center" }} height="full">
          <InView
            as="div"
            threshold={0.4}
            triggerOnce={false}
            onChange={(inView) => {
              if (inView) {
                controls.start({ x: 0, opacity: 1 });
              } else {
                controls.start({ x: -100, opacity: 0 });
              }
            }}
          >
            <Stack spacing={{ base: "8", md: "12" }}>
              <Stack spacing={{ base: "4", md: "6" }} maxW={{ md: "xl", lg: "md", xl: "xl" }}>
                <Text color="secondary" fontWeight="600" fontSize="2xl">
                  Expert Solutions at Affordable Rates
                </Text>
                <Heading as="h1" color="white" size={{ base: "2xl", xl: "3xl" }}>
                  Everything You Need
                </Heading>
                <Text color="white" fontWeight="600" fontSize={{ base: "2xl", xl: "3xl" }}>
                  From Websites to IT Consultancy
                </Text>
              </Stack>

              <Stack direction={{ base: "column", md: "row" }} spacing="3">
                <Button
                  variant="secondary"
                  onClick={() => {
                    ReactGA.event({
                      category: "Contact Button",
                      action: "Click",
                      label: "Contact Me Button Click",
                    });

                    const element = document.getElementById("contact");
                    element?.scrollIntoView({ behavior: "smooth" });
                  }}
                >
                  Get in touch
                </Button>
                <Button
                  variant="primary"
                  _hover={{ bg: "transparent" }}
                  onClick={() => {
                    ReactGA.event({
                      category: "Learn More Button",
                      action: "Click",
                      label: "Learn More Button Click",
                    });

                    const element = document.getElementById("whoarewe");
                    element?.scrollIntoView({ behavior: "smooth" });
                  }}
                >
                  Explore services
                </Button>
              </Stack>
            </Stack>
          </InView>
          <Box w="auto" height="auto" pos="absolute" right={0} top={{ base: "30%", md: "15%", lg: "5%" }}>
            <Img boxSize="full" objectFit="cover" src={HeroImg} alt="Professional working" height="100%" width="100%" />
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};
