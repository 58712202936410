import { Box, Container, Heading, Text, VStack } from "@chakra-ui/react";

export const CookiesPolicy = () => {
  return (
    <Box bg="primary" color="gray.800" py={10}>
      <Container maxW="4xl">
        <VStack align="start" spacing={6}>
          <Heading color="white" as="h1" size="2xl" mb={4}>
            Cookies Policy
          </Heading>

          <Text color="white" fontSize="lg">
            At CodeHardy, we use cookies to improve your experience on our website. This Cookies Policy explains what cookies are, how we use them, and how you can manage your preferences.
          </Text>

          <Heading color="white" as="h2" size="lg" mt={6}>
            What Are Cookies?
          </Heading>
          <Text color="white">Cookies are small text files stored on your device when you visit a website. They help us remember your preferences and enhance your browsing experience.</Text>

          <Heading color="white" as="h2" size="lg" mt={6}>
            How We Use Cookies
          </Heading>
          <Text color="white">
            We use cookies to:
            <ul>
              <li>Understand how you interact with our website</li>
              <li>Remember your preferences and settings</li>
              <li>Provide personalized content and ads</li>
              <li>Analyze website traffic and performance</li>
            </ul>
          </Text>

          <Heading color="white" as="h2" size="lg" mt={6}>
            Types of Cookies We Use
          </Heading>
          <Text color="white">
            We use both session and persistent cookies:
            <ul>
              <li>
                <strong>Session Cookies:</strong> These are temporary cookies that expire when you close your browser.
              </li>
              <li>
                <strong>Persistent Cookies:</strong> These remain on your device until they expire or you delete them.
              </li>
            </ul>
          </Text>

          <Heading color="white" as="h2" size="lg" mt={6}>
            Managing Cookies
          </Heading>
          <Text color="white">You can manage your cookie preferences through your browser settings. You can choose to block or delete cookies, but this may affect your experience on our website.</Text>

          <Heading color="white" as="h2" size="lg" mt={6}>
            Changes to This Policy
          </Heading>
          <Text color="white">We may update this Cookies Policy from time to time. Any changes will be posted on this page with an updated revision date.</Text>

          <Text color="white">If you have any questions about our use of cookies, please contact me at support@code-hardy.com.</Text>
        </VStack>
      </Container>
    </Box>
  );
};
