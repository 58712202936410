import { Box, Center, Container, Grid, Heading, HStack, Icon, SimpleGrid, Text } from "@chakra-ui/react";
import { motion, useAnimation } from "framer-motion";
import { FiArrowRight } from "react-icons/fi";
import { InView } from "react-intersection-observer";

export const Portfolio = () => {
  const MotionBox = motion(Box);
  const controlsY = useAnimation();
  const controlsX = useAnimation();

  const handleInViewChange = (inView: boolean) => {
    controlsY.start({ y: inView ? 0 : 200, opacity: inView ? 1 : 0 });
    controlsX.start({ x: inView ? 0 : -200, opacity: inView ? 1 : 0 });
  };

  return (
    <Box id="portfolio" bg="primary" w="100vw" py="10%" pos="relative" overflow="hidden" height={{ base: "fit-content", lg: "auto" }}>
      {/* Hero Image in the background */}

      <Container p={{ base: 16, lg: 24 }} maxW="full">
        <InView as="div" triggerOnce={false} threshold={0.4} onChange={handleInViewChange}>
          {/* Left Side: Text and Bullets */}
          <Center h="100%" flexDir="column" alignItems="flex-start">
            <MotionBox animate={controlsY} initial={{ y: 200, opacity: 0 }} transition={{ duration: 1 }}>
              <Text pb={2} fontWeight={600} fontSize={{ base: "lg", md: "xl" }} color="secondary">
                latest portfolio
              </Text>
              <Heading pb={2} as="h1" fontWeight={600} size={{ base: "md", md: "lg" }} color="white">
                Check out my latest
              </Heading>
              <Heading pb={4} as="h2" fontWeight={600} size={{ base: "md", md: "lg" }} color="secondary">
                projects
              </Heading>
            </MotionBox>
          </Center>

          <SimpleGrid h="700px" mt={10} columns={{ base: 1, lg: 2 }} spacing={5}>
            <Box borderRadius="lg" overflow="hidden">
              <iframe src="https://muttleysdoggydaycare.com" title="Muttleys Doggy Daycare" width="100%" height="100%"></iframe>
            </Box>
            <Box as={Grid} templateRows={"1fr 1fr"} gap={5}>
              <Box borderRadius="lg" overflow="hidden">
                <iframe src="https://socialsbyhols.com" title="Socials By Hols" width="100%" height="100%"></iframe>
              </Box>
              <Box borderRadius="lg" overflow="hidden" bg="secondary">
                <Box p={5}>
                  <HStack mt="15%">
                    <Icon as={FiArrowRight} color="primaryAccent" />
                    <Heading pb={2} as="h1" fontWeight={700} size={{ base: "md", md: "lg" }} color="primaryAccent">
                      Muttley's
                    </Heading>
                    <Heading pb={2} as="h1" fontWeight={700} size={{ base: "md", md: "lg" }} color="white">
                      Doggy Day Care
                    </Heading>
                  </HStack>
                  <Text lineHeight="1.8" fontSize={{ base: "lg", md: "xl" }} color="whitesmoke" w="100%">
                    I developed a custom website featuring an integrated booking system that helped streamline Muttley's operations and improve their customer experience.
                  </Text>
                </Box>
              </Box>
            </Box>
          </SimpleGrid>
        </InView>
      </Container>
    </Box>
  );
};
