import { Box, Center, Container, Flex, Heading, Icon, Image, SimpleGrid, Text } from "@chakra-ui/react";
import { motion, useAnimation } from "framer-motion";
import { InView } from "react-intersection-observer";
import { MdCircle } from "react-icons/md";

const HeroImg = require("../assets/hero-image.png");
const Image1 = require("../assets/who-are-we-1.png");
const Image2 = require("../assets/who-are-we-2.jpeg");
const Test = require("../assets/test.png");

export const NeedSomethingUnique = () => {
  const MotionBox = motion(Box);
  const controlsY = useAnimation();

  const handleInViewChange = (inView: boolean) => {
    controlsY.start({ y: inView ? 0 : 200, opacity: inView ? 1 : 0 });
  };

  return (
    <Box id="needsomethingunique" bg="primary" w="100vw" py="10%" pos="relative" overflow="hidden" height={{ base: "fit-content", lg: "auto" }}>
      <Container p={{ base: 16, lg: 24 }} maxW="full">
        <InView as="div" triggerOnce={false} threshold={0.4} onChange={handleInViewChange}>
          <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={{ base: "10%", lg: "15%", xl: "10%" }}>
            {/* Left Side: Text and Bullets */}
            <Center h="100%" flexDir="column" alignItems="flex-start">
              <MotionBox animate={controlsY} initial={{ y: 200, opacity: 0 }} transition={{ duration: 1 }}>
                <Text pb={2} fontWeight={600} fontSize={{ base: "lg", md: "xl" }} color="secondary">
                  Need something unique?
                </Text>
                <Heading pb={2} as="h1" fontWeight={600} size={{ base: "md", md: "lg" }} color="white">
                  I’ve Got You
                </Heading>
                <Heading pb={4} as="h2" fontWeight={600} size={{ base: "md", md: "lg" }} color="secondary">
                  Covered
                </Heading>
                <Text lineHeight="2" fontSize={{ base: "lg", md: "xl" }} color="whitesmoke" w="100%">
                  Whatever your project, I provide bespoke solutions tailored to your specific needs. From building custom websites and applications to offering expert IT consultancy or hosting services, I deliver premium results without the premium price tag.
                </Text>
                <Center flexDir="column" mt={"100px"}>
                  <Flex mb={5} w="100%">
                    <Center flex={0.1}>
                      <Icon as={MdCircle} size="10" color="secondary" />
                    </Center>
                    <Center flex={0.9}>
                      <Text color="white">I specialise in creating bespoke websites and applications that are uniquely crafted for your needs. Whether it's a custom app or an interactive platform, I deliver solutions that elevate user experience and showcase your brand.</Text>
                    </Center>
                  </Flex>
                  <Flex mb={5}>
                    <Center flex={0.1}>
                      <Icon as={MdCircle} size="10" color="secondary" />
                    </Center>
                    <Center flex={0.9}>
                      <Text color="white">I provide reliable and secure hosting services, ensuring your website or application stays online and performs at its best. My hosting solutions are scalable, flexible, and backed by expert technical support.</Text>
                    </Center>
                  </Flex>
                  <Flex>
                    <Center flex={0.1}>
                      <Icon as={MdCircle} size="10" color="secondary" />
                    </Center>
                    <Center flex={0.9}>
                      <Text color="white">As an experienced consultant, I can guide you through the fast-changing tech landscape. From strategy to implementation, I ensure your solutions drive growth and innovation.</Text>
                    </Center>
                  </Flex>
                </Center>
              </MotionBox>
            </Center>

            {/* Right Side: Overlapping Images */}
            <Center pos="relative" w="100%">
              <Image src={Test} objectFit="contain" alt="Need Something Unique" borderRadius="lg" height="100%" width="100%" mr={-48} />
            </Center>
          </SimpleGrid>
        </InView>
      </Container>
    </Box>
  );
};
